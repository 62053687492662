.main {
    position: absolute;
    height: 100vh;
    width: 100%;
    background-color: black;
    text-align: center;
  }
  
  .h{
    color: white;
    margin-top: 0px;
    font-size: 25px;
    width: 100%;
  }
  
  
  .p {
    color: rgb(255, 153, 98);
    margin-top: 5px;
    font-size: 12px;
    width: 100%;
   
  }
  
  
  .center {
    height: 60px;
    position: relative;
    margin-top: calc((100vh - 250px)/2);
  
  }
  
  .logo {
    position: relative;
  }
  
  .icone{
    height: 150px;
    width: 150px;
  }
  